<template>
  <div style="display: flex">
    <click-to-edit
      v-model="scope.row.scale.growth_direction"
      style="flex: 1 1 auto; margin-right: 5px; overflow: hidden; overflow-wrap: anywhere"
      placeholder="Введите направление развития"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.growth_direction')"
      type="textarea"
      :word-limit="255"
      show-word-limit
      @input="saveElementPropChange(scope.row, 'scale', {growth_direction:$event})"
    >
    </click-to-edit>
    <el-popover
      placement="top"
      title="Описание"
      width="400"
      trigger="click"
    >
      <el-button
        slot="reference"
        class="table-action-button open-desc-button"
        type="text"
        round
        size="mini"
        icon="fas fa-info-circle"
      ></el-button>
      <div style="white-space: pre-line">
        Используйте направления развития:
        <ul>
          <li>Изучение рабочих регламентов/процедур</li>
          <li>Изучение книги/статьи/др. информационных источников</li>
          <li>Самообучение</li>
          <li>Внешнее обучение</li>
          <li>Участие в проекте</li>
          <li>Руководство проектом</li>
          <li>Исполнение обязанностей вышестоящей/смежной позиции</li>
          <li>Обмен лучшими практиками</li>
          <li>Наставничество по производственным задачам</li>
          <li>Коучинг по управленческим знаниям/навыкам</li>
        </ul>
      </div>
    </el-popover>
  </div>
</template>

<script>


import ClickToEdit from "@/components/ClickToEdit.vue";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";

export default {
  name: 'element-growth-direction-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  computed: {
    scaleDesc() {
      const currentScale = this.scaleTypes.find(s => s.id === this.scope.row.scale.type);
      if( !currentScale ){
        return 'Необходимо сначала выбрать шкалу';
      }
      return currentScale.desc;
    }
  },
  data() {
    return {
      scaleTypes: variables.scaleTypes
    }
  },

  methods: {}
}

</script>

<style>

</style>
